<template>
  <div>
    <ClientOnly>
      <component
        :is="optionComponent"
        :text="text"
        :text2="text2"
        :text2Class="text2Class"
      >
      </component>
    </ClientOnly>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";

export default {
  props: {
    text: String,
    text2: String,
    text2Class: String,
  },
  computed: {
    optionComponent() {
      if (typeof window !== "undefined") {
        return defineAsyncComponent(() => {
          return import(/* @vite-ignore */ `./SplitAnimateContent.vue`).then(
            (module) => module.default
          );
        });
      }
    },
  },
};
</script>
